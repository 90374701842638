
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <div className='Description_Text'>
        <div className="text_describe">My name is Victoria! I love reading and this website is curated specifically for those who love books!</div>
          </div>
          <a className="follow_me" href="https://www.tiktok.com/@readinglover4ever">
          <img className="TikTokLogo" alt=""  src="https://static.wixstatic.com/media/11062b_3a1a34a22c10436caac05a4e0f6a876e~mv2.png/v1/fill/w_46,h_46,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_3a1a34a22c10436caac05a4e0f6a876e~mv2.png" />
          <div className="Follow">Follow Me On TikTok</div>
  
          </a>
        
      </div>
    </div>
  );
}

export default App;
